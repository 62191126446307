import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useGetWidgets } from '@src/hooks/queries/dashboards/widgets';
import { TID, TMongoID } from '@src/types/common';

import { toggleTargetsState, toggleViewState } from '@src/components/business_dashboards/atoms';
import { DefaultTargets } from '@src/components/business_dashboards/edit_targets/default_targets';

import Table from '../tabular_view';
import BriefChartView from './brief_chart_view';

interface IBriefViewProps {
  businessId: TID,
  dashboardId: TMongoID,
  targets: Record<string, number>,
  standardCategoryId: number,
}

const BriefView = ({
  businessId,
  dashboardId,
  targets,
  standardCategoryId,
}: IBriefViewProps) => {
  const query = useGetWidgets({ dashboardId });
  const widgets = useMemo(() => {
    return query.data?.collection || [];
  }, [query.data?.collection]);

  const getDefaultTarget = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()];
    if (!defaultTarget) return 0;

    return defaultTarget[identifier]?.value || 0;
  };

  const isChartView = useRecoilValue(toggleViewState);
  const showTargets = useRecoilValue(toggleTargetsState);
  const renderTableView = () => (
    <Table collection={ widgets } />
  );

  const renderChartView = () => (
    <>
      {
        widgets.map((widget) => (
          <BriefChartView
            key={ widget.id }
            businessId={ businessId }
            className="col-sm-6 col-md-4 pointer"
            dashboardId={ dashboardId }
            target={ showTargets ? (targets[widget.identifier] || getDefaultTarget(widget.identifier)) : undefined }
            widget={ widget }
          />
        ))
      }
    </>
  );

  return (
    isChartView ? renderChartView() : renderTableView()
  );
};

export default BriefView;
