import React from 'react';

import { ICollaborationProps, IExpenseProps, IMonthEndClosingProps, IReconciliationProps, IRevenueProps } from '@src/types/common_operational_dashboard';

import Table from '@src/components/ui_v2/table';

import BusinessCell from '../../components/custom_cells/business_cell';
import BusinessDriveCell from '../../components/custom_cells/business_drive_cell';
import CollaborationCell from '../../components/custom_cells/collaboration_cell';
import ExpenseCell from '../../components/custom_cells/expense_cell';
import IssueCell from '../../components/custom_cells/issue_cell';
import LinkedChatIconWithBadge from '../../components/custom_cells/linked_chat_icon_with_badge';
import MonthEndClosingCell from '../../components/custom_cells/month_end_closing_cell';
import ReconciliationCell from '../../components/custom_cells/reconciliation_cell';
import RevenueCell from '../../components/custom_cells/revenue_cell';
import { IBusinessOwnerConfigFormType } from '../../helper';
import { hasActivePreference } from '../../helper/functional';
import {
  IDashboardData,
  IBusinessCommonProps,
  TViewOperationalDashboard,
  TSetupStatus,
} from '../../types';

interface ITableRowItemProps {
    columnData: IDashboardData;
    preferences?: IBusinessOwnerConfigFormType;
    view?: string | null;
}
const TableRowItem = ({
  columnData,
  preferences,
  view,
}: ITableRowItemProps) => {
  const memoizedBusinesCell = (columnDataItem?: IBusinessCommonProps): React.ReactNode => (
    <BusinessCell user={ view as TViewOperationalDashboard } { ...columnDataItem } />
  );
  const memoizedBusinessDriveCell = (columnDataItem?: IBusinessCommonProps): React.ReactNode => (
    <BusinessDriveCell { ...columnDataItem } />
  );
  const memoizedExpenseCell = (
    columnDataItem?: IExpenseProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ExpenseCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  );
  const memoizedRevenueCell = (
    columnDataItem?: IRevenueProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <RevenueCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  );
  const memoizedReconciliationCell = (
    columnDataItem?: IReconciliationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <ReconciliationCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  );
  const memoizedMonthEndCell = (
    columnDataItem?: IMonthEndClosingProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <MonthEndClosingCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  );
  const memoizedCollaborationCell = (
    columnDataItem?: ICollaborationProps,
    setupStatus?: TSetupStatus,
  ): React.ReactNode => (
    <CollaborationCell
      preferences={ preferences }
      setupStatus={ setupStatus }
      { ...columnDataItem }
    />
  );
  return (
    <Table.Row key={ columnData.id }>
      <Table.Cell>
        {memoizedBusinesCell({
          displayName: columnData?.displayName,
          legalName:   columnData?.legalName,
          icon:        columnData?.icon,
          setupStatus: columnData?.setupStatus,
          businessId:  columnData?.businessId,
        })}
      </Table.Cell>
      <Table.Cell>
        <LinkedChatIconWithBadge
          businessId={ columnData?.businessId ?? 0 }
          unreadCount={ columnData?.unreadBusinessChatCount ?? 0 }
        />
      </Table.Cell>
      {view === 'accountant' && !window.Docyt.currentAdvisor.checkUserAccessToBookgpt() && (
        <Table.Cell>
          {memoizedBusinessDriveCell({
            unreadInboxMessages: columnData?.unreadInboxMessages,
            businessId:          columnData?.businessId,
          })}
        </Table.Cell>
      )}
      <Table.Cell>
        {IssueCell({
          ...columnData?.issues,
          setupStatus: columnData?.setupStatus,
          businessId:  columnData?.businessId,
        })}
      </Table.Cell>
      {hasActivePreference('expense', preferences) && (
        <Table.Cell>
          {memoizedExpenseCell(columnData.expense, columnData?.setupStatus)}
        </Table.Cell>
      )}
      {hasActivePreference('revenue', preferences)
              && (
              <Table.Cell>
                {memoizedRevenueCell(columnData.revenue, columnData.setupStatus)}
              </Table.Cell>
              )}
      { hasActivePreference('continuousReconciliation', preferences) && (
        <Table.Cell>
          {memoizedReconciliationCell(
            columnData.continuousReconciliation,
            columnData.setupStatus,
          )}
        </Table.Cell>
      )}
      { hasActivePreference('monthEndClosing', preferences) && (
        <Table.Cell>
          {memoizedMonthEndCell(
            columnData.monthEndClosing,
            columnData.setupStatus,
          )}
        </Table.Cell>
      )}
      {hasActivePreference('collaboration', preferences) && (
        <Table.Cell>
          {memoizedCollaborationCell(
            columnData.collaboration,
            columnData.setupStatus,
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};
export default TableRowItem;
