import React, { forwardRef, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';

import { useDropdown } from './hooks';

import styles from './styles.module.scss';

interface IDropdownMenuProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  children: React.ReactNode,
}

const DropdownMenu = forwardRef(({
  children,
  className,
  style,
  ...props
}: IDropdownMenuProps, ref): JSX.Element | null => {
  const popover = useDropdown();

  const classes = classNames(
    styles['dropdown-menu'],
    className,
    { [styles['dropdown-menu-visible']]: !popover || popover?.isVisible },
  );
  const childProps = children && typeof children === 'object' && 'props' in children ? children.props : {};
  const { showDropDown } = childProps;

  useEffect(()=>{
    popover?.hide()
  },[showDropDown])

  const menuStyles = { ...style, ...popover?.popperStyles?.popper };
  const menuProps = { ...props, ...popover?.popperAttributes?.popper };

  const hoverHandlers = useMemo(() => ({
    onMouseEnter: () => popover?.show?.(),
    onMouseLeave: () => popover?.hide?.()
  }), [popover]);

  return (
    <div
      ref={ mergeRefs(popover?.popperRef ? [popover?.popperRef, ref] : [ref]) }
      className={ classes }
      role="menu"
      style={ menuStyles }
      { ...menuProps }
      { ...hoverHandlers }
    >
      { children }
    </div>
  );
});

DropdownMenu.displayName = 'DropdownMenu';

export default React.memo(DropdownMenu);
